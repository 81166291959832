<template>
  <div>
    <b-card class="card" :header="'Menu'">
      <b-button @click="onClose" variant="secondary" class="close-button">×</b-button>

      <b-form-group :ref="'focusInput'" >
        <b-form-textarea no-auto-shrink rows="3"
                         max-rows="80" :value="clipboard">
          {{clipboard}}
        </b-form-textarea>
      </b-form-group>

      <b-button @click="onClose">Zatvoriť</b-button>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  props: ['clipboard'],
  data: function () {
    return {

    }
  },
  mounted() {
    this.$nextTick(_ => this.$refs.focusInput.$children[0].focus())
  },
  methods: {
    onClose: async function () {

      this.$emit('close')
    }
  }
})
</script>

<style lang="scss" scoped>
.close-button {
  color: #C4C4C4;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 23px;
  right: 30px;
  font-size: 24px;
  border: none;
  font-weight: 300;
}
</style>
