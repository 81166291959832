<template>
  <div id="subnavigation">
    <div class="container">
      <div class="sub-nav-menu">
        <a :class="{'active-link': active === 'daily'}" @click.prevent="navTo('/app/daily')">Denné menu</a>
        <a :class="{'active-link': active === 'carte'}" @click.prevent="navTo('/app/carte')">À la carte</a>
        <a :class="{'active-link': active === 'catering'}" @click.prevent="navTo('/app/catering')">Catering</a>
      </div>
      <div class="float-right">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {mapActions, mapGetters} from "vuex"

  export default Vue.extend({
    name: 'subnavigation',
    props: ['active'],
    methods: {
      navTo: function (to) {
        if(this.$route.path === to)
          return
        
        this.$router.push(to)
      },
    },
  })
</script>
<style lang="scss" scoped>
  #subnavigation{
    background: white;
    height: 67px;
    display: flex;
    padding: 12px 0;
    box-shadow: 0 1px 0  #EFEFEF;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        margin-right: 30px;
        color: #A6A6A6;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color:#6e6e6e;
        }
        &.active-link {
          color: black;
        }
      }
    }
  }
</style>
