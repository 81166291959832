<template>
  <div>
    <Subnavigation active="daily">
    <b-button class="btn-copy" variant="empty" @click="onOpenModal()">
      <img src="/copy.svg" alt=""> Skopírovať menu
    </b-button>
    </Subnavigation>
    <div class="container d-flex  align-items-center justify-content-between calendar-daily" v-if="weekDays.length">
      <b-button @click="onShiftWeek(false)" variant="empty">
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0167646 5.00015C0.0167646 5.17937 0.086792 5.35857 0.226553 5.49521L4.62673 9.79498C4.90664 10.0685 5.36046 10.0685 5.64025 9.79498C5.92005 9.52157 5.92005 9.07819 5.64025 8.80465L1.74673 5.00015L5.64012 1.19563C5.91991 0.92211 5.91991 0.478774 5.64012 0.205385C5.36032 -0.0682688 4.9065 -0.0682688 4.6266 0.205385L0.226417 4.50509C0.0866332 4.6418 0.0167646 4.821 0.0167646 5.00015Z" fill="#252525"/>
      </svg>
      </b-button>
      <b-button v-for="(day, i) in weekDays" :key="i" class="daily-button flex-grow-1"
                :variant="dailyId === formatDayId(day) ? 'secondary' : 'empty'"
                @click="onChangeDailyId(formatDayId(day))">{{ formatDay(day) }} </b-button>
      <b-button @click="onShiftWeek(true)" variant="empty">
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.98324 5.00015C5.98324 5.17937 5.91321 5.35857 5.77345 5.49521L1.37327 9.79498C1.09336 10.0685 0.63954 10.0685 0.359747 9.79498C0.0799541 9.52157 0.0799541 9.07819 0.359747 8.80465L4.25327 5.00015L0.359883 1.19563C0.0800901 0.92211 0.0800901 0.478774 0.359883 0.205385C0.639676 -0.0682688 1.0935 -0.0682688 1.3734 0.205385L5.77358 4.50509C5.91337 4.6418 5.98324 4.821 5.98324 5.00015Z" fill="#252525"/>
        </svg>
      </b-button>
    </div>
    <div class="container">
      <div class="mb-md-4" v-for="(section, i) in daily" :key="i">
        <b-card class="card">
          <b-overlay :show="busySearch" rounded="lg">
            <b-table v-table-sort="sortableOptions" striped :ref="'table' + section.id" :items="section.recipes" :fields="fields" class="daily-table" :tbody-tr-attr="addTrId" :data-id="section.id">
              <template #head(name)>
                {{ formatDayName(section.id) }}
              </template>

              <template #head(actions)>
                <b-dropdown right class="policy-drop">
                  <template #button-content>
                    <span>...</span>
                  </template>
                  <b-dropdown-item @click.prevent="onRemoveRecipes(section.id)">Vymazať recepty</b-dropdown-item>
                </b-dropdown>
              </template>

              <template #cell(name)="value">
                <div class="pointer-event" @click="onOpenRecipe(value.item.id)">{{value.value}}</div>
              </template>

              <template #cell(dragndrop)="value">
                <span class="drop-index" :data-id="value.item.id"><DragNDrop/></span>
              </template>

              <template #cell(price_per_portion)="value">
                <div>
                  {{ formatPrice(value.value) }} &euro;
                </div>
              </template>

              <template #cell(portions)="value">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button variant="tertiary" :disabled="value.item.added" @click="onChangePortions(+value.value - 1, section.id, value.item.id)"><svg width="7" height="1" viewBox="0 0 7 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="7" height="1" fill="#C4C4C4"/>
                    </svg></b-button>
                  </b-input-group-prepend>

                  <b-form-input type="number" :disabled="value.item.added" :value="value.value" debounce="500" @change="onChangePortions($event, section.id, value.item.id)" min="0" step="1"></b-form-input>

                  <b-input-group-append>
                    <b-button variant="tertiary" :disabled="value.item.added" @click="onChangePortions(+value.value + 1, section.id, value.item.id)"><svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="3" width="7" height="1" fill="#C4C4C4"/>
                      <rect x="3" y="7" width="7" height="1" transform="rotate(-90 3 7)" fill="#C4C4C4"/>
                    </svg></b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>

              <template #cell(price_total)="value">
                <div>
                  {{ formatPrice(value.value) }} &euro;
                </div>
              </template>

              <template #cell(added)="value">
                <template v-if="!demoDomain">
                  <b-button v-if="!value.value" @click="onAddToCart(value.item.id, section.id)">Do zoznamu</b-button>
                  <strong v-else>Pridané</strong>
                </template>
              </template>
              <template #cell(actions)="value">
                <template>
                  <b-dropdown right class="policy-drop">
                    <template #button-content>
                      <span>...</span>
                    </template>
                    <b-dropdown-item @click.prevent="onRemoveRecipe(section.id, value.item.id)">Vymazať</b-dropdown-item>
                  </b-dropdown>
                </template>
              </template>
            </b-table>
            <NothingFound :btn-action="navToRecipes" btn-text="+ Pridať jedlo" v-if="!section.recipes.length">
              <template slot="title">Nemáte pridanú receptúru</template>
              <template slot="body">Na tento deň ešte nemáte naplánovanú žiadnu receptúru. Pokračujte kliknutím na tlačidlo pridať receptúru.</template>
            </NothingFound>
            <b-button v-if="!formatIsPast(section.id) && section.recipes.length" block @click="navTo('/app/recipes')" class="add-recipe">+ Pridať receptúru</b-button>

            <div class="total" v-if="section.price_total">
              <strong class="total-title"></strong>
              <div class="total-actions">
                <strong class="price"></strong>
                <b-button v-if="!demoDomain" @click="onAddAllToCart(section.id)">Do zoznamu</b-button>

              </div>
            </div>
          </b-overlay>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import {mapActions, mapGetters, mapMutations} from "vuex"
import DragNDrop from "@/plugins/app/_layouts/components/drag-n-drop"
import Subnavigation from "@/plugins/app/_layouts/components/subnavigation"
import format from '@/plugins/app/formatters'
import NothingFound from "@/plugins/app/_layouts/components/nothing-found"
import MenuModal from "@/plugins/app/daily/menu-modal"
import wAxios from "@/plugins/w/axios"

export default Vue.extend({
  components: {Subnavigation, DragNDrop, NothingFound},
  props: ['vendorId'],
  data: function () {
    return {
      sortableOptions: { onEnd: this.onDragEnd, handle: ".drop-index" },
      weekDays: [],
      clipboard: '',
      fields: [
        { key: 'dragndrop', label: '', class: 'handler'},
        { key: 'name', label: '', class: 'day-name'},
        { key: 'portions', label: 'Počet porcií'},
        { key: 'added', label: 'Možnosti'},
        { key: 'actions', label: ''},
      ]
    }
  },
  computed: {
    ...mapGetters('daily', ['daily', 'dailyId', 'busySearch']),
    demoDomain() {
      return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
    },
  },
  watch: {
    dailyId: function () {
      this._setWeek(this.dailyId)
      this._preloadClipboard()
    }
  },
  async mounted() {
    const today = moment().format("YYYY-MM-DD")
    await this.loadDaily({dailyId: today})
    this._setWeek(this.dailyId)
    await this._preloadClipboard()
  },
  beforeDestroy() {
    this.updateDailyId(format.dayId(new Date()))
  },
  methods: {
    ...mapActions('daily', [
      'addRecipeToDaily', 'removeRecipeFromDaily',
      'changePortionsOfRecipeInDaily', 'loadDaily',
      'removeAllRecipesFromDaily', 'changeSortingOfRecipesInDaily'
    ]),
    ...mapActions('venues', ['preloadVenues']),
    ...mapActions('cart', ['addToCart']),
    ...mapMutations('daily', ['updateDailyId', 'updateDaily']),

    onDragEnd(event) {
      const newOrder = []
      for (const item of event.target.children) {
        newOrder.push(+item.dataset.id)
      }

      const id = event.target.parentElement.dataset.id
      this.changeSortingOfRecipesInDaily({id, newOrder})
      this.updateDaily([{recipes:[]}])
    },

    onChangePortions(portions, id, recipeId) {
      this.changePortionsOfRecipeInDaily({portions, id, recipeId})
    },

    onChangeDailyId(dailyId) {
      this.loadDaily({dailyId})
    },

    onRemoveRecipe(id, recipeId) {
      this.removeRecipeFromDaily({id, recipeId})
    },

    onRemoveRecipes(id) {
      this.removeAllRecipesFromDaily(id)
    },

    onOpenRecipe(id) {
      this.$router.push(`/app/recipes/${id}`)
    },

    onShiftWeek(next) {
      const dailyId = moment(this.dailyId)[
            next ? 'add' : 'subtract'
          ](7, 'd').format("YYYY-MM-DD")

      this.loadDaily({dailyId})
    },

    async onAddToCart(recipe_id, daily_id) {
      await this.addToCart([{recipe_id, daily_id}])
      this.loadDaily()
    },

    async onAddAllToCart(daily_id) {

      const payload = this.daily[0].recipes.map(item => {
        return {
          recipe_id: item.id,
          daily_id
        }
      })

      await this.addToCart(payload)

      this.loadDaily()
    },

    async copyMenu() {
      try {
        await navigator.clipboard.writeText(this.clipboard ?? '')
        this.$wToast.success('Recepty boli skopírované!')
      }catch (e) {
        console.log(e)
      }
    },

    onOpenModal() {
      this.$modal.show( MenuModal, {
        clipboard: this.clipboard ?? ''
      } )
    },


    async _preloadClipboard() {
      const res = await wAxios.get_auth_data(`v1/list/daily/${this.dailyId}`)
      // console.log('_preloadClipboard', res.copypaste)
      this.clipboard = res.copypaste
    },

    _setWeek(dayId) {
      const week = moment(dayId).format('ww')
      const year = moment(dayId).format('gggg')
      const startOfWeek = moment(`${year}W${week}`).startOf('week')
      this.weekDays = []
      let day = startOfWeek
      for (let i = 0; i < 7; i++) {
        day = day.clone().add(1, 'd')
        this.weekDays.push(day.toDate())
      }
    },

    addTrId(item) {
      return {
        'data-id': item.id
      }
    },

    navTo: function (to) {
      this.$router.push(to)
    },

    navToRecipes: function () {
      this.$router.push('/app/recipes')
    },

    formatDay: format.day,
    formatDayId: format.dayId,
    formatDayName: format.dayName,
    formatNow: format.now,
    formatPrice: format.price,
    formatIsPast: format.isPast,
  }
})
</script>
<style lang="scss">
.btn-copy {
  img {
    max-height: 24px;
    opacity: 0.6;
    margin-right: 5px;
  }
}

.day-name {
  &:first-letter {
    text-transform: uppercase;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  border-top: 1px solid #F1F1F1;
  .total-title {
    font-size: 16px;
    font-weight: 500;
  }
  .total-actions{
    > *{
      margin-left: 30px;
    }
    .btn-secondary {
      padding: 8px 10px;
      font-size: 12px;
    }
    .policy-drop{
      margin-left: 20px;
      padding: 8px;
      button {
        background-color: transparent !important;
        border: none;
        &:after {
          display: none;
        }
        span{
          display: block;
          margin-top: -8px;
          color: #A6A6A6;
        }
      }
    }
    .price{
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.calendar-daily{
  height: 70px;
  max-width: 740px;
  .btn {
    color: #A6A6A6;
    font-size: 14px;
    font-weight: 400;
    &:focus{
      box-shadow: none;
    }
    &.btn-secondary {
      background: none;
      border: none;
      color:#252525;
      &:focus{
        box-shadow: none;
        background: none;
        border-color: transparent;
      }
      &:active{
        color: unset;
        box-shadow: none!important;
      }
    }
  }
}
.add-dropdown {
  width: 330px;
}
.add-recipe{
  background-color:#F6F6F6;
  color: #252525;
  border: none;
  font-weight: 500;
  margin-bottom: 15px;
}
</style>
