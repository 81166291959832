<template>
  <div class="nothing-found">
    <div class="nothing-found-wrap">
     <NotfoundImage/>
      <div class="title"><slot name="title"></slot></div>
      <div class="paragraph"><slot name="body"></slot></div>
      <b-button v-if="btnText" @click="btnAction()">{{ btnText }}</b-button>
    </div>
  </div>
</template>

<script>
import NotfoundImage from "@/plugins/app/_layouts/svg/notfound-img"

export default {
  name: "nothing-found",
  props: ['btnAction', 'btnText'],
  components: { NotfoundImage }
}
</script>